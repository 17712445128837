import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import '../styles/ReportBugForm.css';

const ReportBugForm = () => {
  const [message, setMessage] = useState('');
  const [reporterName, setReporterName] = useState('');
  const [email, setEmail] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    const bug= {'message': message}

    // Send email using EmailJS
    emailjs.send('service_ygvcsd4', 'template_usbn358', bug, 'v7BV0OWochEdhMrg2')
      .then((result) => {
        console.log('Email sent successfully:', result.text);
        setSuccessMessage('Bug report submitted successfully!');
      }, (error) => {
        console.error('Failed to send email:', error.text);
        setSuccessMessage('Failed to submit bug report. Please try again later.');
      });

    // Reset form fields
    setMessage('');
    setReporterName('');
    setEmail('');
  };

  return (
    <div className="report-bug-form-container">
      <h2>Report a Bug</h2>
      {successMessage && <p className="success-message">{successMessage}</p>}
      <form onSubmit={handleSubmit}>
        <label>
          Bug Description:
          <textarea
            name="bug_description"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          />
        </label>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default ReportBugForm;
