import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './styles/Students.css'; 
import { useSelector } from 'react-redux';
import { FaFileUpload } from "react-icons/fa";

const MarkerStudents = () => {
  const [students, setStudents] = useState({});
  const [allStudents, setAllStudents] = useState([]);
  const [error, setError] = useState('');
  const course = localStorage.getItem('course');
  const homeworkTitle = localStorage.getItem('homeworkTitle');
  const navigate = useNavigate();
  const userType = useSelector((state)=>state.auth.userType);
  const [solution, setSolution] = useState('');

  useEffect(() => {
    const fetchStudents = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BE_URL}/classroom/teacher/${course}/${homeworkTitle}`);
        if (response.data && response.data.students && response.data.students.students) {
          const studentData = response.data.students.students;
          setStudents(studentData);
        } 
        const allResponse = await axios.get(`${process.env.REACT_APP_BE_URL}/admin/studentsRegistered`, {
          params: { course }
        });
        setAllStudents(allResponse.data.sort((a, b) => a.name.localeCompare(b.name)));
        const solResponse = await axios.get(`${process.env.REACT_APP_BE_URL}/classroom/students/solution`, {
          headers: {
            course: course,
            homeworkTitle: homeworkTitle
          }
        })
        // console.log(solResponse.data.solution);
        setSolution(solResponse.data.solution);
      } catch (err) {
        console.error('Error fetching students:', err);
        setError('Failed to load students.');
      }
    };

    if (course && homeworkTitle) {
      fetchStudents();
    } else {
      setError('Course or HomeworkTitle not found in localStorage');
    }
  }, [course, homeworkTitle]);

  const handleStudentClick = (student, email) => {
    console.log(student);
    sessionStorage.setItem('name',student?.name);
    sessionStorage.setItem('comments',student?.comments);
    sessionStorage.setItem('link',student?.link);
    sessionStorage.setItem('lastModified',student?.lastModified);
    sessionStorage.setItem('email',email);
    sessionStorage.setItem('grade',student?.grade);
    sessionStorage.setItem('feedback',student?.feedback);
    sessionStorage.setItem('status',student?.status);
    sessionStorage.setItem('solution', solution);
    navigate(`/marker/grade`);
  };

  const handleHWUpload = (name,email)=>{
    console.log(name);
    navigate('/markersubmithomework', {state:{name : name, email :email}});
  }

  return (
    <div className="marker-students-container">
      {/* {console.log(studentLen)} */}
      <h2 className="marker-students-heading">All student's {homeworkTitle.replace(/_/g, ' ')} in {course.replace(/_/g,' ')} - {Object.keys(students).length}/{allStudents.length}</h2>
      {error && <p className="marker-error-message">{error}</p>}
      <div className="marker-students-table-container">
      <table className="marker-students-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Comments</th>
            <th>Status</th>
            <th>Latest Submission</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {/* {Object.keys(students).map((email) => {
            const student = students[email];
            return (
              <tr key={email}>
                <td>{student.name}</td>
                <td>{student.comments}</td>
                <td>{student.status}</td>
                <td>{new Date(student.lastModified).toLocaleDateString()}</td>
                <td>
                  <button 
                    className="marker-grade-btn" 
                    onClick={() => handleStudentClick(student, email)}>
                    View Details
                  </button>
                </td>
              </tr>
            );
          })} */}
         {allStudents.map((allStudent) => {
            const email = allStudent.email;
            const student = students[email] || {};

            return (
              <tr key={email}>
                <td>{allStudent.name}</td>
                <td>{student.comments || ' - '}</td>
                <td>{student.status==='reSubmit' ? "Re-submit" : student.status || 'No file'}</td>
                <td>{student.lastModified ? new Date(student.lastModified).toLocaleDateString() : ' - '}</td>
                <td>
                 { student.link ?
                  <>
                    <button
                      className="marker-grade-btn"
                      onClick={() => handleStudentClick(student, email)
                      }
                    >
                      View Details
                    </button> 
                  </>: 
                  (userType==='admin'? 
                    <> </>:
                  <> No Submission </>)
                  }
                  <button
                    className="marker-grade-btn"
                    onClick={() => handleHWUpload(allStudent.name, email)
                    }
                  >
                  <FaFileUpload />
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      </div>
    </div>
  );
};

export default MarkerStudents;