import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { uploadFileToS3 } from './s3';
import debounce from 'lodash/debounce';
import '../styles/PostHomework.css';
import { useSelector } from 'react-redux';

const PostQuizAns = () => {
    const [level, setLevel] = useState('');
    const [examination, setExamination] = useState('');
    const [subject, setSubject] = useState('');
    const [category, setCategory] = useState('');
    const [questions, setQuestions] = useState([]);
    const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(0);
    const [filteredQuestions, setFilteredQuestions] = useState([]);
    const [inputIndex, setInputIndex] = useState(0);
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [fileURL, setFileURL] = useState('');
    const userType = useSelector((state)=>state.auth.userType);
    const [hier, setHier] = useState({});
    // const levelOptions = ["AQA"];
    // const examinationOptions = ["A-level"];
    // const subjectOptions = ['Physics'];

    const [levelOptions, setLevelOptions] = useState([]);
    const [examinationOptions, setExaminationOptions] = useState([]);
    const [subjectOptions, setSubjectOptions] = useState([]);
    const [categoryOptions, setCategoryOptions] = useState([]);
    

    const fetchQuestions = useCallback(debounce(async () => {
        try {
            const response1= await axios.get(`${process.env.REACT_APP_QUIZ_BE_URL}/api/questions/filter`);
            console.log(response1.data);
            setHier(response1.data);
            setLevelOptions(Object.keys(response1.data));

            const response = await axios.get(`${process.env.REACT_APP_QUIZ_BE_URL}/api/questions`);
            const questionsData = response.data;
            setQuestions(questionsData);

            const uniqueCategories = Array.from(new Set(questionsData.map(q => q.category)));
            uniqueCategories.sort();
            setCategoryOptions(uniqueCategories);
        } catch (error) {
            console.error("Error fetching questions:", error);
        }
    }, 300), []);

    useEffect(() => {
        fetchQuestions();
        return () => {
            fetchQuestions.cancel();
        };
    }, [fetchQuestions]);

    useEffect(() => {
        filterAndSortQuestions(questions); 
    }, [subject, category, questions]);

    const filterAndSortQuestions = (questionsData) => {
        const filtered = questionsData.filter(question =>
            (!level || question.level === level) &&
            (!examination || question.examination === examination) &&
            (!subject || question.subject === subject) &&
            (!category || question.category === category)
        );
    
        const sorted = filtered.sort((a, b) => {
            const imageNumberA = extractImageNumber(a.imgLink);
            const imageNumberB = extractImageNumber(b.imgLink);
            return imageNumberA - imageNumberB;
        });
    
        setFilteredQuestions(sorted);
    };
    
    const extractImageNumber = (imgLink) => {
        const imageName = imgLink.split('/').pop(); 
        const numberMatch = imageName.match(/\d+/g) || [];
        var combinedNumber = numberMatch.join("");
        if (combinedNumber.length === 4) {
            combinedNumber = combinedNumber.slice(0, 3) + "0" + combinedNumber.slice(3);
        }
        // const imageNumberInLink = parseInt(imageName.match(/\d+/)[0], 10); 
        const imageNumberInLink = parseInt(combinedNumber, 10);
        return imageNumberInLink;
    };
    

    const handleShowQuestion = () => {
        filterAndSortQuestions(questions); 
        const index = parseInt(inputIndex, 10);
        if (!isNaN(index)) {
            const matchingQuestion = filteredQuestions.find(question => {
                const imageName = question.imgLink.split('/').pop(); 
                const imageNumberInLink = parseInt(imageName.match(/\d+/)[0], 10); 
                return imageNumberInLink === index;
            });
    
            if (matchingQuestion) {
                console.log(matchingQuestion);
                const questionIndex = filteredQuestions.indexOf(matchingQuestion);
                console.log(questionIndex);
                setSelectedQuestionIndex(questionIndex);
            } else {
                alert('No question found with the specified image number.');
            }
        } else {
            alert('Invalid input. Please enter a valid image number.');
        }
    };

    const handleInputChange = (e) => {
        setInputIndex(e.target.value);
    };

    const handleQuestionClick = (index) => {
        setInputIndex(index);
        const matchingQuestionIndex = filteredQuestions.findIndex(question => {
            const imageName = question.imgLink.split('/').pop();
            const numberMatch = imageName.match(/\d+/g) || [];
            var combinedNumber = numberMatch.join("");
            if (combinedNumber.length === 4) {
                combinedNumber = combinedNumber.slice(0, 3) + "0" + combinedNumber.slice(3);
            }
            // const imageNumberInLink = parseInt(imageName.match(/\d+/)[0], 10); 
            const imageNumberInLink = parseInt(combinedNumber, 10);
            // const imageNumberInLink = parseInt(imageName.match(/\d+/)[0], 10);
            return imageNumberInLink === index;
        });
    
        if (matchingQuestionIndex !== -1) {
            setSelectedQuestionIndex(matchingQuestionIndex);
        } else {
            alert('No question found with the specified image number.');
        }
    };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            setFile(selectedFile);
            setFileURL(URL.createObjectURL(selectedFile));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!level || !examination || !subject || !category) {
            alert('Please fill out all the dropdown fields.');
            return;
        }

        // if (!inputIndex || isNaN(inputIndex) || inputIndex < 1 || inputIndex > filteredQuestions.length) {
        if (!inputIndex || isNaN(inputIndex) || inputIndex < 1 ) {
            alert('Please select a valid question number.');
            return;
        }

        if (!file) {
            alert('Please select a file.');
            return;
        }

        
        setLoading(true);
        try {
            const imgLink = filteredQuestions[selectedQuestionIndex]?.imgLink;
            const baseUrl = process.env.REACT_APP_S3_BASE_URL;
            
            if (!imgLink) {
                throw new Error('No imgLink found for the selected question.');
            }
            
            if (!imgLink.startsWith(baseUrl)) {
                throw new Error('imgLink does not start with the base URL.');
            }
            
            const fileName = file.name;
            const fileExtension = fileName.substring(fileName.lastIndexOf('.'));
            
            const imgPath = imgLink.substring(baseUrl.length);
            const pathWithoutExtension = imgPath.substring(0, imgPath.lastIndexOf('.')).replace('+','-');
            const path = `${pathWithoutExtension}/solution${fileExtension}`;
            
            console.log('Uploading to path:', path);
            
            await uploadFileToS3(file, path);
            
            const questionId = filteredQuestions[selectedQuestionIndex]?._id;
            const ansLink = `${baseUrl}${path}`;
            
            if (questionId) {
                await axios.put(`${process.env.REACT_APP_QUIZ_BE_URL}/api/questions/${questionId}/ansLink`, {
                    ansLink: ansLink
                });
                alert('File submitted & updated successfully.');
                setFileURL('');
                fetchQuestions();
            } else {
                throw new Error('No question ID found for the selected question.');
            }
        } catch (error) {
            console.error('Error submitting file or updating ansLink:', error);
            alert(`Failed to submit file or update ansLink: ${error.message}`);
        } finally {
            setLoading(false);
        }
    };
    
    const handleLevel= (e) => {
        setLevel(e);
        setExaminationOptions(Object.keys(hier[e]));
    };
    const handleExamination = (e) => {
        setExamination(e);
        if (level && hier[level]) {
            setSubjectOptions(Object.keys(hier[level][e] || {}));
        } else {
            setSubjectOptions([]);
        }
    };
    const handleSubject = (e) => {
        setSubject(e);
        if (level && examination && hier[level][examination]) {
            setCategoryOptions(hier[level][examination][e] || []);
        } else {
            setCategoryOptions([]); 
        }
    };
    

    const uniqueNumbers = new Set();
    return (
        <>
      {(userType === 'admin' || userType === 'marker') ? (
        <form className="questionForm items-center justify-center" onSubmit={handleSubmit}>
            <div className="filters">
                <label>
                    <select value={level} onChange={e => handleLevel(e.target.value)}>
                        <option value="">Select Level</option>
                        {levelOptions.map(opt => <option key={opt} value={opt}>{opt}</option>)}
                    </select>
                </label>
                <label>
                    <select value={examination} onChange={e => handleExamination(e.target.value)}>
                        <option value="">Select Examination</option>
                        {examinationOptions.map(opt => <option key={opt} value={opt}>{opt}</option>)}
                    </select>
                </label>
                <label>
                    <select value={subject} onChange={e => handleSubject(e.target.value)}>
                        <option value="">Select Subject</option>
                        {subjectOptions.map(opt => <option key={opt} value={opt}>{opt}</option>)}
                    </select>
                </label>
                <label>
                    <select value={category} onChange={e => setCategory(e.target.value)}>
                        <option value="">Select Category</option>
                        {categoryOptions.map(opt => <option key={opt} value={opt}>{opt}</option>)}
                    </select>
                </label>
            </div>
            <h3>select a question :</h3>
            <div className="question-selector">
                <div className="question-selector-grid">
                    {filteredQuestions.map((question, index) => {
                        const imageNumber = extractImageNumber(question.imgLink);
                        if (uniqueNumbers.has(imageNumber)) {
                            return null; 
                        }
                        uniqueNumbers.add(imageNumber);
                        let buttonClass = 'bg-gray-200'; 
                        if (imageNumber === inputIndex) {
                            buttonClass = 'bg-blue-500 text-white'; 
                        } else if (question.ansLink === "" || !question.ansLink ) {
                            buttonClass = 'bg-red-500 text-white'; 
                        } else {
                            // console.log(imageNumber, question);
                            buttonClass = 'bg-green-500 text-white'; 
                        }

                        return (
                            <button
                            key={index}
                            className={`p-2 rounded ${buttonClass}`}
                            type="button" 
                            onClick={() => handleQuestionClick(imageNumber)}
                        >
                            {imageNumber}
                        </button>
                        );
                    })}
                </div>
            </div>
            <div className="question-display">
                {filteredQuestions.length > 0 && (
                    <div>
                        <h3>Question Number: {inputIndex}</h3>
                        <img src={filteredQuestions[selectedQuestionIndex]?.imgLink} alt="Question" />
                        {filteredQuestions[selectedQuestionIndex]?.ansLink && (
                            <div className="file-preview">
                                <h3>Submitted Solution for the selected question</h3>
                                <iframe
                                    src={filteredQuestions[selectedQuestionIndex]?.ansLink}
                                    title="solution Preview"
                                    style={{ width: '100%', height: '400px', border: 'none', marginTop: '10px' }}
                                ></iframe>
                            </div>
                        )}
                    </div>
                )}
            </div>
            
            <div className="file-input">
                <h3><label>Update the Solution here:</label></h3>
                <input
                    type="file"
                    onChange={handleFileChange}
                    placeholder="Select the file"
                />
                {file && (
                    <div className="file-preview">
                        <iframe
                            src={fileURL}
                            title="File Preview"
                            style={{ width: '100%', height: '400px', border: 'none', marginTop: '10px' }}
                        ></iframe>
                    </div>
                )}
            </div>

            <div className="flex items-center justify-center h-screen">
                <button
                    type="submit"
                    disabled={loading}
                    className={`${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                >
                    {loading ? 'Submitting...' : 'Submit Ans'}
                </button>
            </div>
        </form> 
        ) : (
            <div
              className="unauthorized-message"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '80vh', 
                fontSize: '1.5rem', 
                // color: '#ff4d4d', 
                padding: '20px', 
                textAlign: 'center', 
              }}
            >
              Page Unauthorized..
            </div>
          )}
        </>
    );
};

export default PostQuizAns;
