import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './styles/Homeworks.css'

const Homeworks = () => {
  const course = localStorage.getItem('course');
  const token = useSelector((state) => state.auth.token);
  const [homeworks, setHomeworks] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchHomeworks = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_BE_URL}/classroom/students/all-homeworks-2`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            course,
          },
        });
        setHomeworks(res.data);
        // console.log(res.data);
      } catch (error) {
        console.error('Error fetching homeworks:', error);
      }
    };

    fetchHomeworks();
  }, [course, token]);

  const handleNavigate = (homeworkTitle) => {
    localStorage.setItem('homeworkTitle', homeworkTitle);
    navigate('/sstsubmithomework');
  };

  const handleViewFileClick = async(homework) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BE_URL}/classroom/students/solution`, {
        headers: {
          course: course,            // Pass course from homework
          homeworkTitle: homework.homeworkTitle       // Pass homework title from homework
        }
      });
  
      // Check if the solution is found
      if (response.data) {
        const sol = response.data.solution ? response.data.solution : "/temp.pdf";
        const updatedHomework = { ...homework, solution: sol};
        console.log(updatedHomework);
  
        navigate('/viewFile', { state: { homework: updatedHomework } });
      } else {
        console.error('No solution found for this homework');
      }
    } catch (error) {
      console.error('Error fetching solution:', error);
    }
  };

  const getRowClass = (status) => {
    if (status === 'graded') return 'graded-row'; 
    if (status === 'pending') return 'pending-row';
    return '';
  };

  return (
    <div className="homeworks-container">
      <h1 className="homeworks-heading"> Homeworks : {course.replace(/_/g, ' ')}</h1>
      <div className="heading-students-table-container">
      <table className="homeworks-table">
        <thead>
        <tr>
            <th>Title</th>
            <th>Due Date</th>
            <th>Description</th>
            <th>Files</th>
            <th>Actions</th>
            <th>Status</th>
            {/* <th>Grades</th> */}
          </tr>
        </thead>
        <tbody>
          {/* {console.log(homeworks)}
          {console.log('Due Date:', Date.now())}
          {console.log(homeworks[0])}
          {console.log('Parsed Date:', new Date(homeworks[3]?.dueDate).getTime()) } */}
          {homeworks.map((homework) => (
            <tr key={homework._id} className={getRowClass(homework.status)}> 
              <td>{homework.homeworkTitle.replace(/_/g, ' ')}</td>
              <td>{new Date(homework.dueDate).toLocaleString('en-GB', { 
                  day: '2-digit', 
                  month: 'short', 
                  year: 'numeric', 
                  hour: '2-digit', 
                  minute: '2-digit'
              })}</td>
              <td>{homework.description || '-'}</td>
              <td>
                {homework.files ? (
                  <span 
                  onClick={() => handleViewFileClick(homework)}
                  style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
                >
                  View File
                </span>
                ) : (
                  'No File'
                )}
              </td>
              <td>
              {Date.now() < new Date(homework.dueDate).getTime() || homework.status==='reSubmit'? (
                  <button className="action-button" onClick={() => handleNavigate(homework.homeworkTitle)}>
                    Upload
                  </button>
                ) : (
                  <button className="action-button disabled" disabled>
                    Due date Expired
                  </button>
                )}
              </td>
              <td>{homework.status !== 'reSubmit' ? homework.status : 'Re-Submit' }</td>
              {/* <td>{homework.grades || 'Not Graded'}</td> */}
            </tr>
          ))}
        </tbody>
      </table>
      </div>
    </div>
  );
};



export default Homeworks;