import React, { useState } from 'react';
import axios from 'axios';

const SimilarityCheck = () => {
  const [teacherAnswer, setTeacherAnswer] = useState('');
  const [studentAnswer, setStudentAnswer] = useState('');
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false); // Adding loading state

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setResult(null);
    setLoading(true); // Set loading state before API call

    try {
      const response = await axios.post(`${process.env.REACT_APP_NLP_URL}/evaluate`, {
        teacher_answer: teacherAnswer,
        student_answer: studentAnswer,
      });

      setResult(response.data);
    } catch (err) {
      setError(err.response?.data?.error || 'Something went wrong');
    } finally {
      setLoading(false); // Set loading to false once the request is complete
    }
  };

  return (
    <div className="similarity-check">
      <h1>Answer Similarity Checker</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Teacher's Answer:</label>
          <textarea
            value={teacherAnswer}
            onChange={(e) => setTeacherAnswer(e.target.value)}
            rows="4"
            cols="50"
          />
        </div>
        <div>
          <label>Student's Answer:</label>
          <textarea
            value={studentAnswer}
            onChange={(e) => setStudentAnswer(e.target.value)}
            rows="4"
            cols="50"
          />
        </div>
        <button type="submit" disabled={loading}>
          {loading ? 'Evaluating...' : 'Evaluate'}
        </button>
      </form>

      {error && <div className="error">{error}</div>}

      {result && (
        <div className="result">
          <h3>Results:</h3>
          <p>Similarity Score: {result.similarity_score}</p>
          <p>Semantic Feedback:</p>
          <pre>{result.semantic_feedback}</pre>
        </div>
      )}
    </div>
  );
};

export default SimilarityCheck;
