import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './styles/StatusCount.css';  
import { useNavigate } from 'react-router-dom';


const StatusCount = () => {
  const [homeworkData, setHomeworkData] = useState([]);
  const [allStudents, setAllStudents]= useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [unfiltered,setUnfiltered] = useState([]);
  const [courses, setCourses] = useState([]);
  const isDesktopBrowser = !/Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
  const isMobileView = (window.innerWidth < 1000) || !isDesktopBrowser;

  useEffect(() => {
    const fetchHomeworkData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BE_URL}/classroom/teacher/status-count`);
        setHomeworkData(response.data); 
        setUnfiltered(response.data);
        const allcount = await axios.get(`${process.env.REACT_APP_BE_URL}/admin/course-student-count`);
        // console.log(allcount);
        setAllStudents(allcount.data);
        setLoading(false);
        const response2 = await axios.get(`${process.env.REACT_APP_BE_URL}/classroom/teacher/courses`);
        setCourses(["All Homeworks", ...response2.data.sort()]);
      } catch (err) {
        setError('Error fetching homework data');
        setLoading(false);
      }
    };
    fetchHomeworkData();
  }, []);

  if (loading) {
    return <div className="statusCount-loading">Loading...</div>;
  }

  if (error) {
    return <div className="statusCount-error">{error}</div>;
  }

  const handleCourseSelect = async (course) => {
    console.log(course);
    console.log(typeof(unfiltered));
    try {
      if (course === "All Homeworks") {
        setHomeworkData(unfiltered); 
      } else {
        const newHomeworkData = unfiltered.filter((homework)=> homework.course===course);
        console.log(typeof(newHomeworkData), newHomeworkData);
        setHomeworkData(newHomeworkData); 
      }
    } catch (error) {
      console.error("Error fetching students:", error);
    }
  };
  
  const handleWorkSelect = async (ele) => {
    console.log(ele);
    console.log(typeof(unfiltered));
    try {
      if (ele === "") {
        setHomeworkData(unfiltered); 
      } else {
        const processedEle = ele.replace(/[\s_]/g, "").toLowerCase(); // Remove spaces and underscores, make lowercase
        const newHomeworkData = unfiltered.filter((homework) => {
          const processedTitle = homework.homeworkTitle.replace(/[\s_]/g, "").toLowerCase();
          return processedTitle.includes(processedEle);
        });
        console.log(typeof(newHomeworkData), newHomeworkData);
        setHomeworkData(newHomeworkData); 
      }
    } catch (error) {
      console.error("Error fetching students:", error);
    }
  };
  
  

  const handleNav = (course, homeworkTitle) =>{
    localStorage.setItem('course', course);
    localStorage.setItem('homeworkTitle', homeworkTitle);
    navigate(`/marker/${homeworkTitle}`);
  }

  return (
    <div className="statusCount-table-container">
      <h2>Homework Status Counts</h2>
      <div style={{
        display: 'flex',
        flexDirection: !isMobileView ? 'row' : 'column',
        gap: '10px',
        width: isMobileView ? '90%' : '100%', 
      }}>
        <select 
          onChange={(e) => handleCourseSelect(e.target.value)} 
          style={{ padding: '10px', fontSize: '16px', border: '1px solid #ccc', borderRadius: '5px', outline: 'none' }}
        >
          {courses && courses.map((course, index) => (
            <option key={index} value={course}>
              {course}
            </option>
          ))}
        </select>
        <input 
          onChange={(e) => handleWorkSelect(e.target.value)} 
          placeholder="Type the keyword"
          style={{ padding: '10px', fontSize: '16px', border: '1px solid #ccc', borderRadius: '5px', outline: 'none' }}
        />
      </div>
      <div className='statusCount-table-wrapper'>
        <table className="statusCount-table">
          <thead>
            <tr>
              <th>Classes</th>
              <th>Homework Title</th>
              <th>Not Graded</th>
              <th>Graded</th>
              <th>No-Submissions</th>
              <th>Total Students</th>
            </tr>
          </thead>
          <tbody>
            {homeworkData.map((homework) => {
              const totalNotGraded = homework.statusCounts.submitted + homework.statusCounts.lateSubmission;
              // const totalNoSubmission = homework.statusCounts.pending + homework.statusCounts.reSubmit;
              const totalNoSubmission = allStudents[homework.course] - (homework.statusCounts.graded + totalNotGraded);
              const graded = homework.statusCounts.graded;
              
              return (
                <tr 
                  key={homework._id} 
                  className={totalNotGraded > 0 ? 'highlight-pending' : ''}
                  onClick={()=>{handleNav(homework.course,homework.homeworkTitle )}}
                >
                  <td>{homework.course.replace(/_/g,' ')}</td>
                  <td>{homework.homeworkTitle.replace(/_/g,' ')}</td>
                  <td>{totalNotGraded ? totalNotGraded : '-'}</td>
                  <td>{graded ? graded : '-'}</td>
                  <td>{totalNoSubmission ? totalNoSubmission : '-'}</td>
                  <td>{allStudents[homework.course]}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default StatusCount;
